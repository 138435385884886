import {Factory} from "./factory";
import {$} from "./dom";
import type {ApplicationParams, FactoryDefinitions} from "./types";

export class Application {

    private _params: ApplicationParams

    private _factory: Factory

    constructor(params: ApplicationParams, definitions: FactoryDefinitions, services: object = {}) {
        this._params = params
        this._factory = new Factory(definitions)
        window.app = this
    }

    public params(): ApplicationParams {
        return this._params
    }

    public factory(): Factory {
        return this._factory
    }

    public csrfHeader(): string {
        return 'X-CSRF-Token'
    }

    public csrfParam(): string {
        return $.getMeta('csrf-param') || ''
    }

    public csrfToken(): string {
        return $.getMeta('csrf-token') || ''
    }

    public static dispatchEvent(element: HTMLElement, type: string, data: object = {}) {
        const event = new CustomEvent(type, {
            detail: JSON.stringify(data),
            bubbles: true,
            cancelable: false,
        });

        element.dispatchEvent(event)
    }

    public getCookie(name: string) {
        const cookies = document.cookie.split(';')
            .reduce((accumulator, cookieString) => {
                const [key, value] = cookieString.split('=').map(split => split.trim());
                if (key && value) {
                    accumulator[key] = decodeURIComponent(value);
                }
                return accumulator;
            }, {});
        return name ? cookies[name] || '' : cookies;
    }

    public setCookie(name: string, value: string, options: object = {}) {
        document.cookie = `${name}=${encodeURIComponent(value)}${
            Object.keys(options)
                .reduce((accumulator, key) => accumulator + `;${key.replace(/([A-Z])/g, $1 => '-' + $1.toLowerCase())}=${options[key]}`, '')
        }`;
    }
}
