import type {AnalyticsParams} from "/@/scripts/components/app/types";
import type {AnalyticsEventConfig} from "/@/scripts/components/analytics/types";
import type ym from "yandex-metrika-tag"

export default class Analytics {
    private params: AnalyticsParams
    private events: Array<string>

    constructor(params: AnalyticsParams, events: Array<string> = []) {
        this.params = params
        this.events = events

        this.listenAnalyticsEvents();
    }

    private listenAnalyticsEvents() {
        this.events.forEach((type) => {
            document.addEventListener(type, (event: Event | CustomEvent) => {
                const target = event.target as HTMLElement

                if (target && target.matches('[data-analytics]')) {
                    const config = JSON.parse(target.dataset.analytics) as AnalyticsEventConfig
                    if (config.goals && config.goals.hasOwnProperty(type)) {
                        this.handleGoal(config.goals[type], config.params);
                    }
                }
            })
        })
    }

    private handleGoal(id: string, params?: object) {
        if (window.AppParams.analytics.metrika.initialized) {
            this.handleMetrika(id.replace('{type}', window.app._appointmentType), params)
        }
    }

    private handleMetrika(id: string, params?: object) {
        params = params || {}
        ym(this.params.metrika.id, 'reachGoal', id, params)
    }

    public utm() {
        const params = this.params.utm.params || {};
        params.clientId = this.params.clientId || (window.gaGlobal ? window.gaGlobal.vid : null) || this.params.fakeClientId

        return params
    }
}
