import {$} from '/@/scripts/components/app'
import type {ScriptParams, PlacemarkConstructor} from "./types";
import type ymaps from "yandex-maps";

export class YandexMap {

    static scriptIncluded = false

    private readonly _element: HTMLElement

    private baseUrl: string = 'https://api-maps.yandex.ru/2.1/'

    private apiKey: string = '81593891-f88e-43ff-8a91-4de1c6dc0e9a'

    private map?: ymaps.Map

    private placemarks: Array<PlacemarkConstructor> = []

    private _onInit?: (map: this) => (void)

    private scriptParams: ScriptParams = {
        lang: 'ru_RU',
        apikey: this.apiKey
    }

    private mapState: ymaps.IMapState = {
        zoom: 14,
        controls: ["zoomControl"],
        behaviors: ["drag", "dblClickZoom", "rightMouseButtonMagnifier", "multiTouch"],
    }

    private mapOptions: ymaps.IMapOptions = {
        suppressMapOpenBlock: true,
    }

    constructor(element: HTMLElement, scriptParams: ScriptParams = {}) {
        this._element = element
        Object.assign(this.scriptParams, scriptParams)
    }

    public element(): HTMLElement {
        return this._element
    }

    public withMap(state: ymaps.IMapState = {}, options: ymaps.IMapOptions = {}): this {
        Object.assign(this.mapState, state)
        Object.assign(this.mapOptions, options)

        return this
    }

    public withPlacemark(placemark: PlacemarkConstructor): this {
        this.placemarks.push(placemark)

        return this
    }

    public onInit(callback: (map: this) => (void)) {
        this._onInit = callback

        return this
    }

    public render() {
        const initializer = () => {
            if (YandexMap.scriptIncluded === false) {
                $.loadScript(this.createScriptUrl(), () => {
                    YandexMap.scriptIncluded = true
                    ymaps.ready().then(() => {
                        this.attachMap()
                    })
                })
            } else {
                this.attachMap()
            }
        }

        const scrollHandler = () => {
            if (this.isMapElementVisible()) {
                window.removeEventListener('scroll', scrollHandler)
                initializer()
            }
        }

        if (this.isMapElementVisible()) {
            initializer()
        } else {
            window.addEventListener('scroll', scrollHandler)
        }
    }

    private createScriptUrl(): string {
        return $.createUrl(this.baseUrl, this.scriptParams)
    }

    private createMap(): void {
        this.map = new ymaps.Map(this._element, this.mapState, this.mapOptions)
    }

    private attachMap(): void {
        this.createMap()
        this.createPlacemarks()

        if (this._onInit) {
            this._onInit(this)
        }
    }

    private createPlacemarks(): void {
        this.placemarks.forEach(placemark => {
            this.map?.geoObjects.add(new ymaps.Placemark(
                placemark.geometry,
                placemark.properties,
                placemark.options,
            ))
        })
    }

    private getElementOffset() {
        return this._element.getBoundingClientRect().top + window.scrollY;
    }

    private getWindowBottomOffset() {
        return window.pageYOffset + window.innerHeight;
    }

    private isMapElementVisible() {
        return this.getWindowBottomOffset() > this.getElementOffset()
    }
}
