export class Http {

    public static defaultOptions: RequestInit = {
        mode: 'same-origin',
        credentials: 'same-origin',
        redirect: 'follow'
    };

    public static request(url: string, options?: RequestInit) {
        options = Object.assign({}, this.defaultOptions, options)

        return new HttpRequest(url, options)
    }

    public static urlEncodeFormData(data: FormData): string {
        let s = '';

        const encode = s => encodeURIComponent(s).replace(/%20/g, '+');

        for (const [key, value] of data.entries()) {
            if (typeof value == 'string') {
                s += (s ? '&' : '') + encode(key) + '=' + encode(value);
            }
        }
        return s;
    }
}

export class HttpRequest {

    private options: RequestInit

    private headers: Headers

    private url: string

    constructor(url: string, options?: RequestInit) {
        this.url = url
        if (options) {
            this.options = options
        }
        this.headers = new Headers()
    }

    public get(): this {
        this.options.method = 'GET'
        return this
    }

    public post(): this {
        this.options.method = 'POST'
        return this
    }

    public ajax(): this {
        this.headers.append('X-Requested-With', 'XMLHttpRequest')
        return this
    }

    public withForm(payload: FormData): this {
        this.headers.set('Content-Type', 'multipart/form-data')
        // console.log(payload)
        this.options.body = payload
        return this
    }

    public withFormMultipartData(payload: FormData): this {
        // delete this.headers['Content-Type'];
        // this.headers.set('Content-Type', 'multipart/form-data')
        // console.log(payload)
        this.options.body = payload
        return this
    }

    public withUrlEncoded(payload: FormData): this {
        this.headers.set('Content-Type', 'application/x-www-form-urlencoded')
        this.options.body = Http.urlEncodeFormData(payload)
        return this
    }

    public withJson(payload: any): this {
        this.headers.set('Content-Type', 'application/json')
        this.options.body = JSON.stringify(payload)
        return this
    }

    public withHeader(name: string, value: string, replace: boolean = false): this {
        this.headers.append(name, value)
        return this
    }

    public fetch(): Promise<Response> {
        let excludePaths = ['submit-donor-sperm'];
	let len = Object.keys(this.headers);
	let includeHeader = true;
	for (let x in excludePaths) {
		if (this.url.split('/').includes(excludePaths[x])) {
			includeHeader = false;
		}
	}

	if (includeHeader) {
		this.options.headers = this.headers
	}

        return  fetch(this.url, this.options);
    }

    public fetchJson(): Promise<object> {
        return this.fetch().then((response) => response.json())
    }

    public fetchHtml(): Promise<string> {
        return this.fetch().then((response) => response.text())
    }
}
