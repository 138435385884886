import {$, Application, Http} from "/@/scripts/components/app";
import type {AjaxFormResponse} from "./types";
import {LoadOverlay} from "/@/scripts/components/load-overlay";

export class AjaxForm {

    private _element: HTMLFormElement

    private _loader: LoadOverlay

    private fields: {[key: string]: HTMLElement} = {}

    private _fieldset = HTMLFieldSetElement

    hasErrorClass: string = 'has-error'

    errorMessageClass: string = 'form-error'

    _onSuccess: (this: this, response: AjaxFormResponse) => (void)
    _onPrepared: (this: this, data: FormData, request: Http) => (void)

    constructor(element: HTMLFormElement) {
        this._element = element
        this._fieldset = element.querySelectorAll('fieldset') as NodeListOf<HTMLFieldSetElement>

        this._loader = new LoadOverlay(this._element);

        $.eachIn(this.element(), '[data-group]', (element: HTMLElement) => {
            this.fields[element.dataset.group] = element
        })

        $.on(this.element(), 'submit', (event: Event) => {
            event.preventDefault()
            this.handleSubmit()
        })

        Application.dispatchEvent(element, 'app:form:init')
    }

    public onSuccess(callback: (this: AjaxForm, response: AjaxFormResponse) => (void)): this {
        this._onSuccess = callback
        return this
    }

    public onPrepared(callback: (this: AjaxForm, data: FormData, request: Http) => (void)) {
        this._onPrepared = callback
        return this
    }

    public element(): HTMLFormElement {
        return this._element
    }

    public loader(): LoadOverlay {
        return this._loader
    }

    public action(): string {
        return this.element().action
    }

    public data(): FormData {
        return new FormData(this.element())
    }

    public resetErrors() {
        Object.values(this.fields).forEach((element:HTMLElement) => {
            element.classList.remove(this.hasErrorClass)
            element.querySelectorAll('.' + this.errorMessageClass).forEach((element: HTMLElement) => {
                element.innerHTML = '&nbsp;'
            })
        })
    }

    private block() {
        this.loader().show()
    }

    private disable() {
        this._fieldset.forEach((fieldset) => {
            fieldset.disabled = true
        })
    }

    private enable() {
        this._fieldset.forEach((fieldset) => {
            fieldset.disabled = false
        })
    }

    private unblock() {
        this.loader().hide()
    }

    private handleSubmit() {
        Application.dispatchEvent(this._element, 'app:form:submit')

        this.block()
        this.resetErrors()
        const data = this.data()
        const url = this.action()
        const request = $.request(url).post().withForm(data)

//         for (var pair of data.entries()) {
//     console.log(pair[1]);
// }
        // console.log(data);
        if (this._onPrepared) {
            this._onPrepared.call(this as AjaxForm, data, request)
        }

        this.disable()

        request.fetchJson().then((response: AjaxFormResponse) => {
            this.enable()
            if (response.status === 'failure') {
                this.populateErrors(response.data.errors)
                Application.dispatchEvent(this._element, 'app:form:failure')
            } else if (response.status === 'success') {
                this.handleSuccess(response)
                Application.dispatchEvent(this._element, 'app:form:success')
            }

            this.unblock()
        })
    }

    private populateErrors(errors?: {[key: string]: Array<string>}): void {
        Object.entries(errors)
            .forEach(([fieldName, errorMessages]) => {
                if (this.fields.hasOwnProperty(fieldName)) {
                    let field = this.fields[fieldName]
                    field.classList.add(this.hasErrorClass)
                    field.querySelectorAll('.' + this.errorMessageClass).forEach((element: HTMLElement) => {
                        element.innerText = errorMessages[0]
                    })
                }
             })
    }

    private handleSuccess(response: AjaxFormResponse): void {
        if (this._onSuccess) {
            this._onSuccess.call(this as AjaxForm, response)
        }
    }
}
