import {Http, HttpRequest} from "./http"

export class $ {

    static id(id: string) {
        return document.getElementById(id);
    }

    static class(cls: string) {
        return document.getElementsByClassName(cls);
    }

    static classIn(element: HTMLElement, cls: string) {
        return element.getElementsByClassName(cls);
    }

    static query(query: string): Element | null {
        return document.querySelector(query);
    }

    static queryIn(element: HTMLElement, query: string): Element | null {
        return element.querySelector(query);
    }

    static queryAll(query: string): NodeListOf<Element> {
        return document.querySelectorAll(query);
    }

    static queryAllIn(element: HTMLElement, query: string): NodeListOf<Element> {
        return element.querySelectorAll(query);
    }

    static on(target: EventTarget, type: string, listener: EventListener, options: EventListenerOptions = {}) {
        target.addEventListener(type, listener.bind(target), options)
    }

    static onIn(container: EventTarget, selector: string, type: string, listener: EventListener, options: EventListenerOptions = {}) {
        const decorator = function (event: Event) {
            if (event.target && event.target.matches(selector)) {
                listener.call(event.target, event)
            }
        }

        container.addEventListener(type, decorator, options)
    }

    static each(selector: string, handler: (element: HTMLElement) => void) {
        this.eachIn(document as HTMLElement, selector, handler)
    }

    static eachIn(element: HTMLElement, selector: string, handler: (element: HTMLElement) => void) {
        const nodeList: NodeListOf<HTMLElement> = element.querySelectorAll(selector)
        nodeList.forEach( (element) => {
            handler(element)
        })
    }

    static getMeta(name: string): string | null {
        const element: HTMLMetaElement | null = document.querySelector('meta[name=' + name + ']')

        return element ? element.content : null
    }

    static tag(name: string, content?: string | null): HTMLElement {
        content = content || ''

        const element = document.createElement(name)
        element.innerHTML = content

        return element
    }

    static request(url: string): HttpRequest {
        return Http.request(url)
    }

    static loadScript(src: string, resolve = (): void => {}, reject = (): void => {}) {
        const script = document.createElement('script')

        script.async = true
        script.src = src
        script.onload = resolve
        script.onerror = reject
        document.head.appendChild(script)
    }

    static createUrl(base: string, params = {}, hash: string = ''): string {
        let query = Object
            .entries(params)
            .map(([key, value]) => {
                value = encodeURIComponent(value as string)
                return `${key}=${value}`
            })
            .join('&')

        query = query ? '?' + query : query
        hash = hash ? '#' + hash : hash

        return base + query + hash
    }
}
