import {Collection} from "./collection";
import {$} from './dom'
import type {FactoryDefinitions} from "./types";

export class Factory {

    private readonly selector: string

    private readonly definitions: FactoryDefinitions

    constructor(definitions: FactoryDefinitions = {}, selector: string = '[data-ui]') {
        this.definitions = definitions
        this.selector = selector
    }

    private static parse(element: HTMLElement): string | null {
        const data = {...element.dataset}

        return data.ui ? JSON.parse(data.ui) : null
    }

    public createAll(element: HTMLElement): Collection {
        const collection = new Collection(element)
        const config = Factory.parse(element)

        for (const [name, options] of Object.entries(config)) {
            if (name in this.definitions) {
                collection.attach(name, this.create(element, name, options))
            }
        }

        return collection
    }

    public create(element: HTMLElement, name: string, options?): any {
        const definition = this.definitions[name]
        if (typeof definition === "function") {
            return definition.call(this as Factory, element, options)
        }
    }

    public discover<T extends HTMLElement>(container: T, self: boolean = false): T {
        $.queryAllIn(container, this.selector).forEach((element: HTMLElement) => {
            this.createAll(element)
        })

        if (self && container.matches(this.selector)) {
            this.createAll(container)
        }

        return container
    }
}
