import {$, Animation} from '/@/scripts/components/app'

export class LoadOverlay {

    private element: HTMLElement

    private overlay: HTMLElement

    private options = {
        tag: 'span',
        class: 'load-overlay',
    }

    constructor(element: HTMLElement, applyImmediately: boolean = false, options?: object) {
        Object.assign(this.options, options)

        this.element = element
        this.overlay = $.tag(this.options.tag)
        this.overlay.classList.add(this.options.class)
        this.overlay.style.transition = 'opacity .4s ease-in-out'
        this.element.appendChild(this.overlay)


        if (applyImmediately) {
            this.show()
        } else {
            this.hide()
        }
    }

    public show() {
        this.overlay.style.opacity = '0'
        this.overlay.style.display = 'block'
        this.overlay.classList.add('animate-show')
    }

    public hide() {
        this.overlay.classList.remove('animate-show')
        this.overlay.style.display = 'none'
        this.overlay.style.opacity = '0'
    }
}
