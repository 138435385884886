export class Collection {

    private components = {}

    private element: HTMLElement

    private property: string

    constructor(element: HTMLElement, property: string = 'components') {
        this.element = element
        this.property = property
        element[property] = this
    }

    public attach<T>(name: string, component: T): T {
        return this.components[name] = component
    }

    public remove(name: string) {
        this.components[name] = null
    }

    public unset(name: string) {
        delete this.components[name]
    }

    public detach(name: string): any {
        const reference = this.components[name]
        this.components[name] = undefined

        return reference
    }
}
